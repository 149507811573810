import logo from "./logo.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" height="128px" />

        <a
          className="App-link"
          href="https://dankmoon.finance"
          target="_blank"
          rel="noopener noreferrer"
        >
          DankMoon
        </a>

        <p className="App-head-description">
          A new <strong>autonomous</strong>, <strong>deflationary</strong> token
          with <strong>holder rewards</strong> on the Binance Smart Chain
          network.
        </p>
      </header>

      <header className="App-headnav">
        <a
          className="App-headnav-item"
          href="https://bscscan.com/"
          rel="noopener noreferrer"
        >
          <span className="App-headnav-item-text">Explore on BscScan</span>
        </a>
        <a
          className="App-headnav-item App-headnav-item-main"
          href="https://pancakeswap.com"
          href="https://poocoin.app/tokens/"
          rel="noopener noreferrer"
        >
          <span className="App-headnav-item-text">Buy on PancakeSwap</span>
        </a>
        <a
          className="App-headnav-item"
          href="https://poocoin.info/"
          rel="noopener noreferrer"
        >
          <span className="App-headnav-item-text">Live TradingView</span>
        </a>
      </header>

      <p>
        <strong>Note:</strong> When using PancakeSwap to exchange tokens,{" "}
        <strong>set the Slippage to 11%</strong> or more!
      </p>

      <p className="App-description" style={{ paddingTop: "3px" }}>
        <hr></hr>
        <h3>🐣 Token Details</h3>
        <p>
          <strong>🚿 Total Supply:</strong> 1,000,000,000,000,000{" "}
          <strong>DANKMOON</strong>.
        </p>
        <p>
          <strong>🔒 Un-ruggable:</strong> Contract ownership renounced.
        </p>
        <p>
          <strong>🔥 LP Burn:</strong> 95% of initial supply added to
          PancakeSwap <strong>DANKMOON/BNB LP</strong> liquidity fund. 100% of
          resulting LP is burned.
        </p>
        <hr></hr>
        <h3>📜 Transaction Fee Breakdown</h3>
        <p>
          👐 <strong>5%</strong> of every transaction is held in the token pool,
          giving value to all holders of DankMoon.
        </p>
        <p>
          🔁 <strong>5%</strong> is transferred to PancakeSwap's liquidity pool
          for <strong>DANKMOON</strong> via the contract. The resulting LP
          tokens are locked in the contract for eternity.
        </p>
        <hr></hr>
        <h2>💡 Why DankMoon?</h2>
        <h3>🙌 Hold to Earn</h3>
        <p>
          Like other deflationary tokens with reflection-based static rewards,
          DankMoon rewards holders over time. As mentioned above, 5% of every
          transaction is redistributed among token existing holders.
        </p>
        <h3>⚙ Automatic Liquidity</h3>
        <p>
          The second 5% fee (mentioned above) is added to the PancakeSwap
          Liquidity Pool for the <strong>DANKMOON/BNB LP</strong> pair.
        </p>
        <h3>💀 Punishes Sellers</h3>
        <p>
          Sellers (especially early ones) are punished by the "Tokenomics"
          behind the smart-contract. Like all transfers of the token, 10% of the
          total amount is held for redistribution and providing liquidity. This
          hurts sellers and rewards holders.
        </p>
        <h3>🧠 Completely Autonomous</h3>
        <p>
          Ownership of the contract has been renounced upon creation, initial
          liquidity deposit, and finally, liquidity lock and burn. Please have a
          look at the transactions below (coming soon) detailing these events.
        </p>
        <h3>💚 Getting Involved</h3>
        <p>
          Although the token contract is autonomous and considered immutable
          once created, there is still much work to be done! It's important to
          spread the word about DankMoon, but there are bigger projects to come:
          improving our website, social media presence, coin listing
          applications, and more!
        </p>
        <p>
          <a
            href="https://discord.gg/Fymn8SPrPB"
            rel="noopener noreferrer"
            target="_blank"
          >
            Join our Discord server
          </a>{" "}
          if you're interested in talking with other members of the DankMoon
          community!
        </p>
        <hr></hr>
        <h2>🔏 Creation Event</h2>

        <p>
          Note: Will be updated momentarily after contract creation.
          Alternatively, these transactions can be found on BscScan.
        </p>

        <p>1. Contract creator has renounced it's ownership of the contract:</p>
        <p>
          <ul>
            <li>
              <a
                style={{ fontFamily: "monospace" }}
                rel="noopener noreferrer"
                target="_blank"
                href="#"
              >
                Coming Soon
              </a>
            </li>
          </ul>
        </p>
        <p>
          2. Contract creator added 95% (5% reserved for burns, events,
          marketing, etc) of the initial supply to PancakeSwap{" "}
          <strong>DANKMOON/BNB LP</strong>:
        </p>
        <p>
          <ul>
            <li>
              <a
                style={{ fontFamily: "monospace" }}
                rel="noopener noreferrer"
                target="_blank"
                href="#"
              >
                Coming Soon
              </a>
            </li>
          </ul>
        </p>

        <p>3. Contract creator burned 100% of initial LP:</p>
        <p>
          <ul>
            <li>
              <a
                style={{ fontFamily: "monospace" }}
                rel="noopener noreferrer"
                target="_blank"
                href="#"
              >
                Coming Soon
              </a>
            </li>
          </ul>
        </p>
      </p>
    </div>
  );
}

export default App;
